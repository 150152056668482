import { createNamespacedHelpers } from "vuex";
const feedModule = createNamespacedHelpers("feed");
const popupModule = createNamespacedHelpers("popup");
export default {
  computed: {
    ...feedModule.mapState([
      "loading",
      "feeds",
      "currentPost",
      "hasNext",
      "deleteLoadingState",
    ]),
  },
  methods: {
    ...feedModule.mapActions([
      "FETCH_FEED",
      "TOGGLE_FEED_POST_LIKE",
      "REPORT_FEED",
      "FETCH_SINGLE_POST",
      "FETCH_SINGLE_POST_PUBLIC",
      "DELETE_USER_POST",
    ]),
    ...feedModule.mapMutations(["SET_SINGLE_POST", "CLEAR_FEED"]),
    ...popupModule.mapMutations(["SET_SHEET"]),
    commentsHandler(post_id) {
      this.SET_SHEET({
        showSheet: true,
        sheetComponent: "feed-comments",
        componentProps: {
          post_id,
        },
        sheetOptions: {
          fullscreen: true,
          showAppBar: true,
          appBarTitle: "Comments",
        },
      });
    },
    toggleLikeHandler(post_id, list = false) {
      this.TOGGLE_FEED_POST_LIKE({ post_id, list });
    },
    reportPostHandler(post_id) {
      this.REPORT_FEED(post_id);
    },
  },
};
