<template>
  <svg
    width="27"
    height="23"
    viewBox="0 0 27 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    v-if="liked"
  >
    <path
      d="M19.0214 11.2619C17.5379 9.78343 15.5682 8.97517 13.4725 8.98049C9.17459 8.99537 5.67798 12.5385 5.67798 16.8789V17.9843C5.67798 19.5478 6.47944 20.9595 7.82195 21.7607C9.16436 22.5619 10.7874 22.5972 12.1634 21.855C13.0003 21.4036 13.9997 21.4036 14.8366 21.855C15.4958 22.2106 16.2114 22.3877 16.926 22.3877C17.703 22.3876 18.4788 22.1781 19.1782 21.7607C20.5206 20.9595 21.3221 19.5478 21.3221 17.9843V16.8026C21.322 14.7081 20.505 12.7404 19.0214 11.2619Z"
      fill="#F39D88"
    />
    <path
      d="M5.15479 7.01958L4.73143 6.71198C3.40179 5.74593 1.53414 6.04178 0.568205 7.37137H0.568153C-0.397838 8.70097 -0.10205 10.5686 1.2276 11.5347L1.65095 11.8423C2.17882 12.2258 2.79149 12.4104 3.39894 12.4104C4.32142 12.4104 5.23178 11.9845 5.81428 11.1829C6.78017 9.85321 6.48438 7.98557 5.15479 7.01958Z"
      fill="#F39D88"
    />
    <path
      d="M26.4319 7.44244C25.4659 6.11284 23.5981 5.81695 22.2686 6.78305L21.8452 7.09065C20.5157 8.05664 20.2198 9.92428 21.1859 11.2539C21.7684 12.0557 22.6786 12.4815 23.6011 12.4815C24.2085 12.4815 24.8213 12.2968 25.3491 11.9133L25.7725 11.6057C27.1021 10.6397 27.3979 8.77203 26.4319 7.44244Z"
      fill="#F39D88"
    />
    <path
      d="M12.478 3.99812L12.0476 2.67358C11.8017 1.91642 11.2755 1.30038 10.566 0.938937C9.8567 0.577442 9.04896 0.513897 8.29185 0.759956C7.53469 1.00596 6.9186 1.53209 6.5571 2.24148C6.19561 2.95086 6.13206 3.75854 6.37812 4.51571L6.80849 5.84024C7.21734 7.09843 8.38867 7.90005 9.64534 7.90005C9.94977 7.90005 10.2595 7.85296 10.5643 7.75392H10.5643C11.3215 7.50791 11.9375 6.98173 12.299 6.27234C12.6604 5.56296 12.724 4.75528 12.478 3.99812Z"
      fill="#F39D88"
    />
    <path
      d="M18.7082 0.786773C17.1451 0.278888 15.4603 1.1374 14.9525 2.70045L14.5221 4.02493C14.2761 4.7821 14.3397 5.58978 14.7011 6.29916C15.0625 7.00854 15.6786 7.53473 16.4357 7.78074C16.7407 7.87977 17.0501 7.92686 17.3547 7.92686C18.6113 7.92686 19.7827 7.12525 20.1916 5.86705L20.6219 4.54252C21.1297 2.97947 20.2712 1.29466 18.7082 0.786773Z"
      fill="#F39D88"
    />
  </svg>

  <!-- <img v-else src="./paw-not-liked.png" alt="" /> -->

  <svg
    v-else
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
  >
    <path
      d="M24.8438 15C26.1382 15 27.1875 13.9507 27.1875 12.6562C27.1875 11.3618 26.1382 10.3125 24.8438 10.3125C23.5493 10.3125 22.5 11.3618 22.5 12.6562C22.5 13.9507 23.5493 15 24.8438 15Z"
      stroke="black"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M5.15625 15C6.45067 15 7.5 13.9507 7.5 12.6562C7.5 11.3618 6.45067 10.3125 5.15625 10.3125C3.86183 10.3125 2.8125 11.3618 2.8125 12.6562C2.8125 13.9507 3.86183 15 5.15625 15Z"
      stroke="black"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10.7812 9.375C12.0757 9.375 13.125 8.32567 13.125 7.03125C13.125 5.73683 12.0757 4.6875 10.7812 4.6875C9.48683 4.6875 8.4375 5.73683 8.4375 7.03125C8.4375 8.32567 9.48683 9.375 10.7812 9.375Z"
      stroke="black"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M19.2188 9.375C20.5132 9.375 21.5625 8.32567 21.5625 7.03125C21.5625 5.73683 20.5132 4.6875 19.2188 4.6875C17.9243 4.6875 16.875 5.73683 16.875 7.03125C16.875 8.32567 17.9243 9.375 19.2188 9.375Z"
      stroke="black"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M21.473 18.2641C20.2971 17.611 19.4267 16.52 19.0511 15.2285L19.051 15.2286C18.796 14.3515 18.2632 13.5808 17.5327 13.0324C16.8022 12.484 15.9135 12.1875 15 12.1875C14.0866 12.1875 13.1978 12.484 12.4674 13.0324C11.7369 13.5808 11.2041 14.3515 10.9491 15.2286L10.949 15.2285C10.5734 16.52 9.70296 17.611 8.52711 18.2641C7.68009 18.7232 7.04223 19.4907 6.74574 20.4074C6.44926 21.3241 6.51689 22.3197 6.93461 23.1879C7.35234 24.0561 8.08813 24.7303 8.98945 25.0706C9.89078 25.411 10.8885 25.3915 11.7759 25.0162C13.8418 24.169 16.1582 24.169 18.2242 25.0162C19.1116 25.3919 20.1096 25.4117 21.0112 25.0714C21.9128 24.7311 22.6488 24.0569 23.0667 23.1885C23.4845 22.3201 23.552 21.3242 23.2553 20.4073C22.9586 19.4905 22.3204 18.723 21.473 18.2641H21.473Z"
      stroke="black"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: "paw-icon",
  props: { liked: { type: Boolean, default: false } },
};
</script>

<style lang="scss" scoped></style>
